
import { docReady } from "@js/libs/helpers";

docReady(() => {

	let anchorlinks = document.querySelectorAll('a[href^="#"]');
 
    for (let link of anchorlinks) {
        let hashval = link.getAttribute('href');
        let targetEl = document.querySelector(hashval);
        let y = targetEl.offsetTop;

        link.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo({top: y, behavior: 'smooth'});
            history.pushState(null, null, hashval);
        });
    }

});