
import { docReady } from "@js/libs/helpers";

docReady( () => {

	const header = document.getElementById('site-header');
	if ( !header ) {
		return;
	}

	let state = "top";

	const stuck = () => {
		if ( state !== "stuck" ) {
			state = "stuck";
			header.setAttribute('data-state', "stuck" );
		}
	};

	const minified = () => {
		if ( state !== "minified" ) {
			state = "minified";
			header.setAttribute('data-state', "minified" );
		}
	};

	const unstuck = () => {
		if ( state !== "top" ) {
			state = "top";
			header.setAttribute('data-state', "top" );
		}
	};

	const scroll = () => {
		if ( window.pageYOffset > 90 ) {
			minified();
		} else if ( window.pageYOffset > 46 ) {
			stuck();
		} else {
			unstuck();
		}
	};

	scroll();
	window.addEventListener('scroll', scroll );

});
