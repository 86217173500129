
import { onClick, docReady } from "@js/libs/helpers";

docReady(() => {

    const mobileMenu = document.getElementById('mobile-menu');
	if ( !mobileMenu ) {
		return;
	}

    const menuToggle = document.querySelector('[data-menutoggle]');

	onClick(menuToggle, function (e) {
		e.preventDefault();

        let status = this.getAttribute('data-menutoggle');

		if (status === 'close') {
            this.setAttribute('data-menutoggle', 'open');
            mobileMenu.classList.add('open');
            document.querySelector('body').classList.add('overflow-y-hidden');
            return;
        }

        if (status === 'open') {
            this.setAttribute('data-menutoggle', 'close');
            mobileMenu.classList.remove('open');
            document.querySelector('body').classList.remove('overflow-y-hidden');
            return;
        }
	});

    let mobileMenulinks = document.querySelectorAll('#mobile-menu nav a[href^="#"]');
 
    for (let link of mobileMenulinks) {
        onClick(link, function (e) {
            let status = menuToggle.getAttribute('data-menutoggle');

            if (status === 'open') {
                menuToggle.setAttribute('data-menutoggle', 'close');
                mobileMenu.classList.remove('open');
                document.querySelector('body').classList.remove('overflow-y-hidden');
                return;
            }
        });
    }

});