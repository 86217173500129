import focusTrap from 'focus-trap';
import { docReady } from "@js/libs/helpers";

let timer;

let open = false;

let closeCallback;

const getElements = new Promise(resolve => {
	docReady(() => {

		const modal = document.createElement('div');
		modal.setAttribute('class', 'invisible opacity-0 transition-alpha fixed inset-0 z-200 bg-offblack-90 flex items-center justify-center' );
		modal.setAttribute('id', 'modal-container');
		document.body.appendChild(modal);


		const content = document.createElement('div');
		content.setAttribute('class','invisible opacity-0 transition-alpha');
		modal.appendChild(content);

		const close = document.createElement('button', {
			type: 'button'
		});
		close.setAttribute('type', 'button');
		close.setAttribute('class', `focus-key:border bg-white md:bg-transparent rounded-full text-orange hover:text-white absolute 
			right-0 top-0 m-4 border-orange w-8 h-8 p-2` );
		close.innerHTML = `
			<span class="visually-hidden">Close</span>
			<svg version="1.1"
			     xmlns="http://www.w3.org/2000/svg" 
			     xmlns:xlink="http://www.w3.org/1999/xlink" 
			     xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
			     x="0px"
			     y="0px"
			     class="svgfull fill-current"
			     width="14.2px"
			     height="14.2px"
			     viewBox="0 0 14.2 14.2"
			     enable-background="new 0 0 14.2 14.2"
			     xml:space="preserve">
				<path d="M9.2,7.1l4.5-4.5c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L7.1,5L2.6,0.4C2-0.1,1-0.1,0.4,0.4s-0.6,1.5,0,2.1L5,7.1l-4.5,4.5 c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l4.5-4.5l4.5,4.5c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4 c0.6-0.6,0.6-1.5,0-2.1L9.2,7.1z"/>
			</svg>
		`;
		modal.appendChild(close);

		const trap = focusTrap(modal, {
			escapeDeactivates: true,
			clickOutsideDeactivates: false,
		});

		close.addEventListener('click', () => {
			dismiss(trap, modal, content);
		});

		modal.addEventListener('click', e => {
			
			if (e.target === modal || e.target === content) {
				dismiss(trap, modal, content);
			}
		});

		resolve({
			modal,
			content,
			trap,
		});
	});

});

const reveal = () => {
	clearTimeout(timer);
	getElements.then(({ modal, trap, content }) => {
		modal.classList.remove('invisible');
		modal.classList.remove('opacity-0');
		timer = setTimeout(() => {
			open = true;
			trap.activate();
			content.classList.remove('opacity-0');
			content.classList.remove('invisible');
		}, 300);
	});
};

export const dismiss = () => {
	clearTimeout(timer);
	getElements.then(({ modal, trap, content }) => {
		open = false;
		modal.classList.add('invisible');
		modal.classList.add('opacity-0');
		timer = setTimeout(() => {
			// do a reset, something might have added classes, and clear out content.
			content.setAttribute('class','opacity-0 invisible transition-alpha');
			content.innerHTML = '';
		}, 400);
		trap.deactivate();
		if (typeof closeCallback === 'function') {
			closeCallback(trap, modal, content);
			closeCallback = false;
		}
	});
};

document.addEventListener('keydown', e => {
	if (!open) { return; }

	if (e.key === 'Escape') {
		dismiss();
	}
});

window.getElements = getElements;

export const Modal = function (contentGenerator, cb) {

	window.addEventListener('close.modal', e => {
		dismiss();
	});

	getElements.then(({ trap, modal, content }) => {
		if (typeof contentGenerator === 'function') {
			// eslint-disable-next-line
			contentGenerator(content);
		} else if (typeof contentGenerator === 'string') {
			// eslint-disable-next-line
			content.innerHTML = contentGenerator;
		}

		closeCallback = cb;
		reveal();
	});
};

export default { Modal };
