import { Exposure } from "@js/libs/exposure";
import { qsa, docReady } from "@js/libs/helpers";
import homeSlides from "@js/parts/home-slides";
import standardCarousel from "@js/libs/standard-carousel";

docReady(() => {

	qsa('[data-exposure]', el => {
		return new Exposure(el);
	});

	const slideshow = document.getElementById('homeslides');

	if (slideshow) {
		homeSlides(slideshow);
	}

	const standardCarouselContainers = document.querySelectorAll('[data-standard-carousel]');
	if (standardCarouselContainers) {
		standardCarouselContainers.forEach(el => standardCarousel(el, '[data-slide]'));
	}
});
