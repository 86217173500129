import Flickity from 'flickity';

export const homeSlides = function (container) {

	const carousel = container.querySelector('[data-carousel]');
	if (!carousel) { return false; }

	const $title = container.querySelector('[data-title-container]');
	const $content = container.querySelector('[data-content-container]');
	const $prev = container.querySelector('[data-prev]');
	const $next = container.querySelectorAll('[data-next]');
	const slideElements = container.querySelectorAll('[data-slide]');

	const slides = {};
	let flkty;

	slideElements.forEach((element, i) => {
		const title = element.querySelector('[data-title]');
		const content = element.querySelector('[data-content]');

		slides[i] = {
			title: title.innerHTML,
			content: content.innerHTML
		};
	});

	const setContent = (t, $el) => {

		if ($el) {
			$el.innerHTML = t;
			return true;
		}

		return false;

	};

	const options = {
		md: {
			cellSelector: '[data-slide]',
			prevNextButtons: false,
			pageDots: false,
			adaptiveHeight: false,
			setGallerySize: false,
			wrapAround: true,
		},
		sm: {
			cellSelector: '[data-slide]',
			prevNextButtons: false,
			pageDots: false,
			adaptiveHeight: false,
			setGallerySize: true,
			wrapAround: true,
		},
	};

	const makeFlkty = function () {
		if (flkty) {
			flkty.destroy();
		}

		if (window.innerWidth <= 600) {
			flkty = new Flickity(carousel, options.sm);
			return flkty;
		}

		flkty = new Flickity(carousel, options.md);

		let timer;
		flkty.on('change', i => {

			requestAnimationFrame(() => {
				clearTimeout(timer);
				const { title, content } = slides[i] || { title: '', content: '' };

				$title.style.opacity = 0;
				$content.style.opacity = 0;

				timer = setTimeout(() => {
					setContent(title, $title);
					setContent(content, $content);
					requestAnimationFrame(() => {
						$title.style.opacity = 1;
						$content.style.opacity = 1;
					});

				}, 400);
			});
		});

		return flkty;
	};

	if ($prev) {
		$prev.addEventListener('click', () => {
			if (flkty) {
				flkty.previous();
			}
		});
	}

	if ($next) {
		$next.forEach((el, i) => {
			el.addEventListener('click', () => {
				if (flkty) {
					flkty.next();
				}
			});
		});
	}

	makeFlkty();

	let resizeTimer;
	window.addEventListener('resize', e => {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
			makeFlkty();
		}, 100);
	});

	window.flkty = flkty;
};

export default homeSlides;
