/* CSS */
import "@js/parts/css";

// General Libraries:
import handorgel from 'handorgel';
import { docReady, qsa } from "@js/libs/helpers";
docReady( () => {
	qsa('.handorgel', element => {
		const accordion = new handorgel( element, {
			multiSelectable: false,
			headerOpenedClass: 'handorgel__header--opened',
		});
		console.log( accordion );
	});
});
// import 'a11y_accordions';
// e.g. changing opening/closing transition times

import 'what-input';

// Our App
import "@js/parts/modal-triggers";
import "@js/parts/lazyloading";
import "@js/parts/galleries";
import "@js/parts/sticky-header";
import "@js/parts/mobile-menu";
import "@js/parts/anchor-links";
// import "@js/parts/forms";