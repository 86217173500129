import { Modal } from "@js/libs/modal";
import { onClick, docReady } from "@js/libs/helpers";
import standardCarousel from "@js/libs/standard-carousel";

docReady(() => {
	onClick('[data-modal]', function (e) {
		e.preventDefault();
		const data = this.getAttribute('data-modal');
		let contentGenerator;

		let onClose = false;

		if (data === "floorplan") {

			const floorplanString = this.getAttribute('data-floorplans');
			const download = this.getAttribute('data-download');

			const floorplans = floorplanString.trim().split(',');


			contentGenerator = function (content) {
				content.classList.add('max-outer');
				content.classList.add('max-h-full');
				let slides = ``;

				floorplans.forEach((floorplan, i) => {
					slides = `
						${slides}
						<div class="floorplan-slide absolute w-full h-full flex items-center items-start
							${i === 0 ? 'z-1' : 'z-0'}
							">
							<img src="${floorplan}" class="max-h-full m-auto mt-8 mb-4 max-w-full w-auto h-auto block">
						</div>
					`;
				});

				content.innerHTML = `

					<div class="w-full">
						<div class="relative w-xl max-w-full py-16 h-full mx-auto flex flex-col items-start justify-center">
							
							<div class="h-0 w-full relative pb-16x9">
								<div class="absolute inset-0">
									<div class="floorplan-slides w-full h-full">
										${slides}
									</div>
								</div>
							</div>
							${download ? `
							<div class="mt-8 mx-auto">
								<a href="${download}" class="button" download="download">Download Floorplans</a>
							</div>
							` : ``}
						</div>
					</div>
					
				`;

				const el = content.querySelector('.floorplan-slides');
				if ( floorplans.length > 1 ) {
					standardCarousel(el, '.floorplan-slide');
				}
			};

		} else if (data === "carousel") {

			// Find the parent...
			const container = this.parentElement;

			const imgElements = container.querySelectorAll('img');

			let slides = ``;
			imgElements.forEach(img => {

				const { srcset, src, sizes } = img;

				slides = `
				${slides}
				<div data-slide class="w-full h-full flex items-center justify-center">
					<img src="${src}" srcset="${srcset}" sizes="${sizes} class="w-auto max-w-full h-auto max-h-full object-fit-cover" />
				</div>
			`;

			});

			const el = document.createElement('div');
			el.setAttribute('class', `w-full h-full`);
			el.innerHTML = slides;

			contentGenerator = function (content) {
				content.appendChild(el);
				content.setAttribute('class', 'transition-alpha h-full flex items-center w-full py-12 md:p-12 lg:p-24');
				standardCarousel(el, '[data-slide]');
			};

		} else if (data === "team-member") {

			const teamMemberId = this.getAttribute('data-teammember');
			const target = document.getElementById(teamMemberId);

			if (target) {
				const inner = target.querySelector('[data-modal-content]');
				if (!inner) {
					return;
				}

				contentGenerator = function (content) {
					content.classList.add("max-h-screen");
					content.classList.add("overflow-auto");
					content.classList.add("scrolling-touch");
					content.classList.add("w-full");
					content.classList.add("md:w-auto");
					content.classList.add("md:mx-6");
					content.appendChild(inner);
				};

				onClose = function () {
					target.appendChild(inner);
				};

			} else {
				return;
			}

		} else {

			// It's an id, let's assume so...
			const target = document.getElementById(data);
			console.log(target);
			if (target) {
				const inner = document.querySelector('[data-modal-content]');
				console.log(inner);
				if (!inner) {
					return;
				}

				contentGenerator = function (content) {
					content.classList.add("max-h-screen");
					content.classList.add("overflow-auto");
					content.classList.add("scrolling-touch");
					content.classList.add("w-full");
					content.classList.add("md:w-auto");
					content.classList.add("md:mx-6");
					content.appendChild(inner);
				};

				onClose = function () {
					target.appendChild(inner);
				};

			} else {
				return;
			}
		}

		Modal(contentGenerator, onClose);
	});
});
